<script>
import moment from 'moment'
export default {

    data(){
        return {
            cd:'',
            da_distance:'',
            timer: ''
        }
    },
    computed: {
        compute_user() {
          return this.$store.state.authfack.user || []
        },
        compute_date(){
            let a = new Date(this.compute_user.Expired_at * 1000);
            return moment(a).format('YYYY-MM-DD HH:mm')
        },
    },
    methods: {
        testing(){
            let now = new Date()
            let countDownDate = new Date(this.compute_user.Expired_at * 1000);

            // Find the distance between now and the count down date
              var distance = countDownDate - now;
              this.da_distance = countDownDate - now;
              // console.log(distance)
              // Time calculations for days, hours, minutes and seconds
              var days = Math.floor(distance / (1000 * 60 * 60 * 24));
              var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
              var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
              var seconds = Math.floor((distance % (1000 * 60)) / 1000);

              // Display the result in the element with id="demo"
              this.cd = days + "d " + hours + "h "
              + minutes + "m " + seconds + "s ";
        }
    },
    created(){

        let self = this
        
        this.timer = setInterval(function() {
            if(self.compute_user.Expired_at){
                self.testing()
                  if (self.da_distance < 0) {
                    clearInterval(this.timer);
                    // console.log('footer here expired')
                  }
            }
            else{
                self.da_distance = -1
                clearInterval(this.timer);
                // console.log('footer here expired')
            }
        },1000)

        // this.$on('stop_footer', function(){
        //     clearInterval(x)
        // })
    },
    destroyed() {
      // console.log('destroyed')
      clearInterval(this.timer);
      this.timer = ''
    }
}
</script>

<template>
    <footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <!-- <div class="col-sm-6">{{ new Date().getFullYear()}} © Maxwell.</div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    Designed with
                    <i class="mdi mdi-heart text-danger"></i> by
                    <a href="https://themesbrand.com/" target="_blank" class="text-reset">Themesbrand</a>
                </div>
            </div> -->
            <div class="text-center">
                {{ new Date().getFullYear()}} © Maxwell.

                <span>Account expired in {{compute_date}}</span>
                <br>
                Countdown in {{cd}}
            </div>
        </div>
    </div>
</footer>
</template>