export const menuItems = [
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 2,
        label: "menuitems.dashboard.text",
        icon: "uil-home-alt",
        // badge: {
        //     variant: "primary",
        //     text: "menuitems.dashboard.badge"
        // },
        link: "/"
    },
    {
        id: 3,
        label: "menuitems.user.text",
        icon: "uil-user",
        subItems: [
            {
                id: 4,
                label: "menuitems.user.list.list",
                link: "/user/list",
                parentId: 3
            },
            {
                id: 5,
                label: "menuitems.user.list.create",
                link: "/user/create",
                parentId: 3
            },
        ]
    },
    {
        id: 6,
        label: "menuitems.customer.text",
        icon: "uil-users-alt",
        subItems: [
            {
                id: 7,
                label: "menuitems.customer.list.list",
                link: "/customer/list",
                parentId: 6
            },
            {
                id: 8,
                label: "menuitems.customer.list.create",
                link: "/customer/create",
                parentId: 6
            },
        ]
    }
];

